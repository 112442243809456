import { Paper, Box, CircularProgress } from "@mui/material";
import React, { useRef, useEffect, useState } from 'react';
import moment from "moment";
import { useParams } from "react-router-dom";
import tracker from "../assets/tracker.png";
import * as alarmeService from "../services/alarmeService";
import { useDispatch } from "react-redux";
import { Col, Row, Container } from "react-bootstrap"
import SquareIcon from '@mui/icons-material/Square';
import * as alarmeServices from "../services/alarmeService";
import * as qgbtServices from "../services/qgbtService";
import * as releServices from "../services/relesService";
import * as inversorServices from "../services/inversoresService";
import * as energyMeterServices from "../services/energyMeterService";
import { inversorChartOptions } from "../utils/utils";
import { delay } from "../utils/utils"



export default function UnifilarDiagram() {
    const date = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    };

    const { usinaId } = useParams();
    const iframeRef = useRef(null);
    const [equipamentsStatus, setEquipamentsStatus] = useState([])
    const [html, setHtmlContent] = useState()
    const [loading, setLoading] = useState(false);
    const [alarmes, setAlarmes] = useState([])
    const [qgbtsStatus, setQgbtsStatus] = useState([])
    const [circuitBreackersStatus, setCircuitBreackersStatus] = useState([])
    const [inversorData, setInversorData] = useState([])
    const [measurerData, setMeasurerData] = useState([])
    const dispatch = useDispatch();

    async function getData() {
        const response = await fetch('https://mvtoth-files.s3.amazonaws.com/unifilar_diagram(2).txt');
        const data = response.status != 200 ? "Diagrama não encontrado" : await response.text()
        setHtmlContent(data);
    }

    async function updatePage() {
        const filters = {
            nome: "",
            usina: usinaId,
            tipo_equipamento: "EnergyTransformer",
            status: "ativo",
            data_inicio: null,
            data_fim: null,
        };

        const [alarmes, qgbts, circuitBreackers, inversors, measurers] = await Promise.all([
            alarmeServices.getAllAlarmsWithoutPagination({
                filters: filters,
                currentPage: 0,
                itemsPerPage: 100
            }),
            qgbtServices.getQgbtLastRecord(usinaId),
            releServices.findAllLastRegister(usinaId),
            inversorServices.findAllLastRegister(usinaId),
            energyMeterServices.getEnergyMeterLastRecord(usinaId)
        ])


        setAlarmes(alarmes.alarmes)
        setQgbtsStatus(qgbts)
        setCircuitBreackersStatus(circuitBreackers)
        setInversorData(inversors)
        setMeasurerData(measurers)
    }

    useEffect(async () => {
        setLoading(true);

        await getData()
        await updatePage()

        setLoading(false);

        let counter = 0;

        const intervalId = setInterval(() => {
            counter++;
            if (counter === 60) {
                updatePage();
                counter = 0;
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        changeUnifilarDiagramStatus()
    }, [measurerData])


    async function changeUnifilarDiagramStatus() {
        await delay(300);
        checkMeasurer()
        checkCircuitBreackers()
        checkQgbts()
        checkEnergyTransformerAlarms()
        sumInversorData()
    }

    function checkMeasurer() {
        if (measurerData?.potencia == 0) {
            const right_arrow_value = document.querySelector('[id*="right-arrow-value"]')
            if (right_arrow_value)
                right_arrow_value.textContent = `${measurerData?.geracao} kW/h`

            disableArrow("left")
        } else {
            const element = document.querySelector('[id*="left-arrow-value"]')
            if (element)
                element.textContent = `${measurerData?.medicao_distribuida} kW/h`

            disableArrow("right")
        }
    }

    function disableArrow(disabled_arrow) {
        const arrow = document.getElementById(`${disabled_arrow}-arrow`)
        const arrow_text = document.getElementById(`${disabled_arrow}-arrow-text`)
        const arrow_value = document.getElementById(`${disabled_arrow}-arrow-value`)
        if (arrow && arrow_text && arrow_value) {
            arrow.style.display = 'none'
            arrow_text.style.display = 'none'
            arrow_value.style.display = 'none'
        }
    }

    function checkCircuitBreackers() {
        circuitBreackersStatus.forEach((CBStatus, index) => {
            const voltage = CBStatus?.tensao_a + CBStatus?.tensao_b + CBStatus?.tensao_c
            const current = CBStatus?.corrente_a + CBStatus?.corrente_b + CBStatus?.corrente_c
            const circuitBrackerStatus = document.getElementById(`dj${index + 1}-status`)
            if (!circuitBrackerStatus) { return }
            if (voltage + current == 0) {
                const elements = document.querySelectorAll(`[id*="dj${index + 1}"]`)
                elements.forEach((element) => {
                    if (element) {
                        element.classList.add("red")
                    }
                })
                circuitBrackerStatus.textContent = "ABERTO"
            } else {
                circuitBrackerStatus.textContent = "FECHADO"
            }
        })
    }

    function checkQgbts() {
        qgbtsStatus.forEach((qgbt, index) => {
            const qgbtStatus = document.getElementById(`qgbt${index + 1}-status`)
            if (!qgbtStatus) { return }

            if (qgbt.status) {
                qgbtStatus.textContent = "FECHADO"
            } else {
                const elements = document.querySelectorAll(`[id*="qgbt${index + 1}"]`)
                elements.forEach((element) => {
                    if (element) {
                        element.classList.add("red")
                    }
                })
                qgbtStatus.textContent = "ABERTO"
            }
        })
    }

    function checkEnergyTransformerAlarms() {
        alarmes.forEach((alarme) => {
            const element = document.querySelector(`[id*="${alarme?.alarmeTipo?.descricao}"]`)

            if (element) {
                element.classList.add('red');
            }
        })
    }

    function sumInversorData() {
        let current = 0
        let pot_ac = 0
        let pot_dc = 0

        inversorData.forEach((inversor) => {
            current += inversor?.corrente_a
            pot_ac += inversor?.potencia_total_ac
            pot_dc += inversor?.potencia_total_dc
        })
        const pot_ac_element = document.querySelector('[id*="pot-ac"]')
        if (pot_ac_element)
            pot_ac_element.textContent = `${pot_ac.toFixed(2)} kW`

        const pot_dc_element = document.querySelector('[id*="pot-dc"]')
        if (pot_dc_element)
            pot_dc_element.textContent = `${pot_dc.toFixed(2)} kW`

        const invs_current_element = document.querySelector('[id*="invs-current"]')
        if (invs_current_element)
            invs_current_element.textContent = `${current.toFixed(2)} A`
    }

    let UnifilarDiagram = (
        <>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "75vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (<>
                <Paper
                    style={{ minHeight: " 90vh", padding: "15px" }} elevation={3}
                >
                    <Container >
                        <Row className="mb-3">
                            <Col className="pt-2 pb-sm-3" >
                                <img src={tracker} alt="" style={{ marginRight: "5px" }} />

                                <b style={{ fontSize: "20px", marginLeft: 8, marginTop: -3 }}>
                                    Diagrama Unifilar
                                </b>
                            </Col>
                            <Col md={5}>
                                <Paper style={{ backgroundColor: "#fafdf5" }}>
                                    <SquareIcon sx={{ color: "green" }} /> Ativo<br />
                                    <SquareIcon sx={{ color: "red" }} /> Inativo
                                </Paper>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div
                                    className="text-center"
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Paper>
            </>)
            }
        </>
    )

    return UnifilarDiagram

}